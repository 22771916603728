<template>
  <div class="search">
    <div class="clearfix">
      <el-select
        v-if="!isCash"
        class="fl"
        v-model="search.state"
        :placeholder="$t('allRedEnvelope')"
        size="small"
      >
        <el-option
          v-for="item in searchOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- <el-select
        v-if="isCash"
        class="fl"
        v-model="search.is_withdraw"
        :placeholder="$t('Allredenvelope')"
        size="small"
      >
        <el-option
          v-for="item in searchDeposit"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <el-select
        class="fl inputWidth"
        v-model="search.sort"
        size="small"
        v-if="!isCash"
      >
        <el-option
          v-for="item in sortList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <CompanySelect
        class="fl"
        :class="isCash ? 'inputWidth1' : 'inputWidth'"
        v-model="search.company_name"
      ></CompanySelect>
      <span class="fr">
        <el-button size="small" type="primary" @click="searchChange">{{
          $t("search")
        }}</el-button>
        <el-button size="small" @click="resetChange">{{
          $t("reset")
        }}</el-button>
      </span>
    </div>
  </div>
</template>
<script>
import CompanySelect from "~/baseComponents/CompanySelect";

export default {
  props: {
    isCash: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CompanySelect,
  },
  data() {
    return {
      search: {
        sort: "1",
      },
      searchOptions: [
        {
          value: "1",
          label: this.$t('Availableredenvelope'),
        },
        {
          value: "2",
          label: this.$t('Redenvelopesuse'),
        },

        {
          value: "3",
          label: this.$t('Expiredredenvelope'),
        },
      ],
      searchDeposit: [
        {
          value: "1",
          label: "已提现红包",
        },
        {
          value: "0",
          label: "未提现红包",
        },
      ],
      sortList: [
        {
          value: "1",
          label: this.$t('mostrecentpick'),
        },
        {
          value: "2",
          label: this.$t('smalldenomination'),
        },

        {
          value: "3",
          label: this.$t('expirationpriority'),
        },
      ],
    };
  },
  methods: {
    searchChange() {
      let params = Object.assign({}, this.search);
      if (!params.company_name) {
        delete params.company_name;
      }
      if (this.isCash) {
        delete params.sort;
      }
      this.$emit("search", params);
    },
    resetChange() {
      this.search.company_name = "";
      console.log(this.search.company_name);
      this.search = {
        sort: "1",
      };
    },
  },
};
</script>
<style lang="less" scoped>
.inputWidth {
  width: 15%;
  margin-left: 15px;
}
.inputWidth1 {
  width: 20%;
  // margin-left: 15px;
}
</style>