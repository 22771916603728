<template>
  <div>
    <el-autocomplete
      :placeholder="placeholder ? placeholder : $t('ccompanyPlaceholder')"
      v-model="company"
      :fetch-suggestions="queryCompany"
      size="small"
      :trigger-on-focus="false"
      style="width: 100%"
      clearable
      :validate-event="false"
    ></el-autocomplete>
  </div>
</template>

<script>
export default {
  name: "companySelectComponents",
  props: ["countryId", "cityId", "value", "placeholder"],
  data() {
    return { company: "" };
  },
  computed: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.company = this.value;
    });
  },
  watch: {
    countryId() {
      this.company = "";
    },
    cityId() {
      this.company = "";
    },
    company(nv) {
      this.$emit("input", nv);
    },
    value(nv) {
      this.company = nv;
    },
  },
  methods: {
    async queryCompany(queryString, cb) {
      try {
        let parmas = {
          company_name: queryString,
          source: this.PJSource,
        };
        if (this.countryId) {
          parmas.country_id = this.countryId;
        }
        if (this.cityId) {
          parmas.city_id = this.cityId;
        }
        if (this.PJSource == 17) {
          parmas.usage = 2;
        }
        let data
        // 走玉漭的逻辑
        if (this.PJID === 'YMP') {
          data = await this.$store.dispatch(
              "API_ymp_company/company_associateSearch",
              parmas
          );
        } else {
          parmas.nopage = 1
          data = await this.$store.dispatch(
              "baseStore/company_associateSearch",
              parmas
          );
        }
        if (data.success) {
          data.data.forEach((ee) => {
            ee.value =
              this.LOCALE == "en"
                ? ee.name_en
                  ? ee.name_en
                  : ee.name_zh
                : ee.name_zh
                ? ee.name_zh
                : ee.name_en;
          });
          cb(data.data);
        }
      } catch {}
    },
    clearInfo() {
      this.company = "";
    },
  },
  components: {},
};
</script>

<style scoped lang="less">
</style>
