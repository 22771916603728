<template>
  <div class="myRedEnvelope">
    <div class="tabBlock">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('BusinessRedEnvelope ')" name="1">
          <div class="myTitle">
            <search @search="searchChange"></search>
          </div>
          <div class="businessContent clearfix" v-loading="loading">
            <div
              v-for="(it, index) in myBusiness"
              :key="index"
              class="fl business"
            >
              <businessDetail
                :item="it"
                @delSuccess="delSuccess"
              ></businessDetail>
            </div>
            <div
              class="noDataContent"
              v-if="myBusiness.length == 0 && !loading"
            >
              <noDataImg />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('CashRedEnvelope')" name="2">
          <div class="cashContent clearfix">
            <div class="cashTitle clearfix">
              <div class="fl">
                <span class="tips">
                  {{$t('cashamount')}}（￥）：{{ withdraw / 100 || "-" }}</span
                >
                <!-- <span class="mainColor">提现</span> -->
              </div>
              <!-- <div class="fr mainColor cursor">提现记录</div> -->
            </div>
            <div class="cashSearch">
              <search @search="searchCashChange" :isCash="true"></search>
            </div>
          </div>
          <div class="tableBlock" v-if="activeName == '2'">
            <el-table
              :data="tableData"
              style="width: 100%"
              :height="tableHeight"
              v-tableHeight="{ bottomOffset: 74 }"
              :tableLoading="cashLoading"
            >
              <!-- <el-table-column prop="id" label="ID" width="100"> </el-table-column> -->
              <el-table-column prop="name" :label="$t('Company_n')">
                <template slot-scope="scope">
                  <span>
                    {{
                      scope.row.company_name_en
                        | priorFormat(scope.row.company_name_zh, LOCALE)
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('envelopeNumber')" prop="receive_no">
              </el-table-column>
              <el-table-column :label="$t('RedEnvelopeName')">
                <template slot-scope="scope">
                  {{
                    scope.row.topic_en | priorFormat(scope.row.topic_zh, LOCALE)
                  }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('amount')" width="200">
                <template slot-scope="scope">
                  {{scope.row.amount/100|textFormat}}
                </template>
              </el-table-column>
              <el-table-column :label="$t('collectionTime')">
                <template slot-scope="scope">
                  {{ scope.row.receive_time | secondFormat("LLL") }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div
      class="usage mainColor cursor"
      v-if="activeName == '1'"
      @click="toUsed"
    >
      {{$t('usageRecord')}}
    </div>
    <div class="paginationBlock" v-if="activeName == '1'">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :page-size="limit"
        :current-page.sync="start"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <div class="paginationBlock" v-if="activeName == '2'">
      <el-pagination
        layout="total, prev, pager, next"
        :total="cashToal"
        :page-size="cashLimit"
        :current-page.sync="cashStart"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import businessDetail from "~bac/components/conference/redEnvelopeAdmin/myRedEnvelope/business";
import cashDetail from "~bac/components/conference/redEnvelopeAdmin/myRedEnvelope/cash";
import search from "~bac/components/conference/redEnvelopeAdmin/myRedEnvelope/search";
export default {
  components: { businessDetail, cashDetail, search },
  data() {
    return {
      activeName: "1",
      myBusiness: [],
      tableData: [],
      total: 0,
      limit: 8,
      start: 1,
      cashStart: 1,
      cashLimit: 10,
      cashToal: 0,
      cashLoading: false,
      loading: false,
      tableHeight: 100,
      withdraw: "",
    };
  },
  mounted() {
    this.getList();
    this.getCashList();
  },
  methods: {
    async getList(val) {
      this.loading = true;
      try {
        let params = {
          user_id: this.USER_INFO.id,
          start: this.start - 1,
          limit: this.limit,
        };
        let _params = Object.assign(params, val);
        let result = await this.$store.dispatch(
          "baseConsole/myBusinessRedEnvelope",
          _params
        );
        if (result.success) {
          this.myBusiness = result.data;
          this.total = result.total;
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getCashList(val) {
      try {
        let params = {
          user_id: this.USER_INFO.id,
          start: this.cashStart - 1,
          limit: this.cashLimit,
        };
        let _params = Object.assign(params, val);
        let result = await this.$store.dispatch(
          "baseConsole/myCashRedEnvelope",
          _params
        );
        if (result.success) {
          this.tableData = result.data;
          this.withdraw = result.is_withdraw_balance;
          this.cashToal = result.total;
        }
      } catch (e) {
        console.log(e);
      }
    },
    toUsed() {
      this.$router.push("/console/conference/myUsedList");
    },
    handlePageChange(page) {
      this.start = page;
      this.getList();
    },
    searchChange(parmas) {
      this.start = 1;
      this.getList(parmas);
    },
    searchCashChange(parmas) {
      this.cashStart = 1;
      this.getCashList(parmas);
    },
    delSuccess() {
      this.start = 1;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.myRedEnvelope {
  .myTitle {
    padding: 10px 12px 20px;
  }
  .businessContent {
    padding: 0 12px;
    height: calc(100vh - 240px);
    overflow-y: auto;
    .business {
    }
  }
  .noDataContent {
    position: relative;
    height: 500px;
  }
  .usage {
    position: absolute;
    top: 15px;
    right: 32px;
  }
  .cashContent {
    padding: 0 12px;
  }
  .cashTitle {
    margin: 10px 0 30px;
    .tips {
      font-size: 20px;
      font-weight: bold;
      margin-right: 12px;
    }
  }
  .cashSearch {
    margin-bottom: 12px;
  }
}
</style>