<template>
  <div>
    <div class="cashTitle clearfix">
      <div class="fl">
        <span class="tips"> {{$t('cashamount')}}（￥）：230</span>
        <span class="mainColor">提现</span>
      </div>
      <div class="fr mainColor cursor">提现记录</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.cashTitle {
    margin: 10px 20px 0 0;
  .tips {
    font-size: 20px;
    font-weight: bold;
    margin-right: 12px;
  }
}
</style>