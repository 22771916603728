<template>
  <div class="businessItem">
    <div class="itemTitle tc textOverfolw">
      {{$t('congratulations')}}
    </div>
    <div class="orderFull">
      <div class="closeDiv cursor" @click="delData(item)">
        <i class="el-icon-close closeIcon"></i>
      </div>
      <div class="price tc">
        <span v-if="item.currency == 2">$</span>
        <span v-else>￥</span>
        <span>{{ item.discount / 100 || "-" }}</span>
      </div>
      <div class="tc totalFull">
        <span v-if="item.has_threshold"
          >( {{$t("overUser",{
            money:(
              item.threshold / 100 || "-"
            )
          })}})
          </span>
        <span v-else>{{$t('Nothreshol')}}</span>
      </div>
      <div class="tc date">
        {{ $t("effectiveDate") }}：
        <span v-show="item.using_time_type == 1">
          {{ item.using_start_time | secondFormat("LL") }} -
          {{ item.using_end_time | secondFormat("LL") }}</span
        >
        <!-- <span v-show="item.using_time_type == 2"
          >{{ $t("todayAvailable", { day: item.effective_days }) }}
        </span>
        <span v-show="item.using_time_type == 3">
          {{ $t("nextAvailable", { day: item.effective_days }) }}
        </span> -->
      </div>
    </div>
    <div class="detail">
      <div class="mt textOverflow">
        {{$t('setCompanyt')}}：{{
          item.company_name_en | priorFormat(item.company_name_zh, LOCALE)
        }}
      </div>
      <div class="mt">{{ $t("envelopeNumber") }}：{{ item.receive_no }}</div>
      <div class="clearfix mt">
        <div class="fl">{{ $t("instructions") }}：</div>
        <el-popover placement="right-start" width="200" trigger="hover">
          <div >
            {{ item.instruction_en | priorFormat(item.instruction_zh, LOCALE) }}
          </div>
          <div
            slot="reference"
            class="fl cursor textOverflow"
            :class="LOCALE == 'en' ? 'instructioEn' : 'instructios'"
          >
            {{ item.instruction_en | priorFormat(item.instruction_zh, LOCALE) }}
          </div>
        </el-popover>
      </div>
    </div>
    <div>
      <div class="command tc" v-if="isCopy">{{ item.receive_pass }}</div>
      <div
        class="lookBtn tc cursor"
        @click="isCopyClick"
        v-if="!isCopy && item.status != 2"
      >
        {{$t('lookPass')}}
      </div>
      <div
        class="copyBtn tc cursor"
        v-if="isCopy"
        @click="copyText(item.receive_pass)"
      >
        {{$t('copyPass')}}
      </div>
    </div>
    <div class="isUsed" v-if="item.status == 2">
      <el-image
        v-if="LOCALE == 'en'"
        class="isUsedImg"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/useden.png"
      ></el-image>
      <el-image
        v-else
        class="isUsedImg"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/usedzh.png"
      ></el-image>
    </div>
    <div class="isUsed" v-if="item.status == 3">
      <el-image
        v-if="LOCALE == 'en'"
        class="isUsedImg"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/expireden.png"
      ></el-image>
      <el-image
        v-else
        class="isUsedImg"
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/expiredzh.png"
      ></el-image>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isCopy: false,
    };
  },
  methods: {
    isCopyClick() {
      this.isCopy = true;
    },
    copyText(text) {
      if (!text) return;
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = `${text}`;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success(this.$t("copySuccess"));
      this.isCopy = false;
    },
    delData(val) {
      // if (!this.inspectButton("mem:yrm:del")) return;
      this.$confirm(this.$t("delete_envelope?"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          let params = {
            envelope_record_id: val.id,
            user_id: this.USER_INFO.id,
          };
          let result = await this.$store.dispatch(
            "baseConsole/delMyRedEnvelope",
            params
          );
          if (result.success) {
            this.$delTip();
            this.$emit("delSuccess");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.businessItem {
  width: 347px;
  height: 542px;
  background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/booth/bg.png")
    no-repeat;
  margin:0 45px 20px 0;
  position: relative;
  .closeDiv {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    .closeIcon {
      font-size: 24px;
      display: none;
    }
  }
  &:hover {
    .closeIcon {
      transition: 1s;
      display: block;
    }
  }
  div {
    color: #fff;
  }
  .itemTitle {
    font-size: 24px;
    line-height: 70px;
  }
  .orderFull {
    padding-bottom: 30px;
    width: 300px;
    margin: 0 auto;
    .price {
      color: #ff8569;
      font-size: 48px;
      padding: 30px 0 6px;
      font-weight: bold;
    }
    .date {
      color: #999;
      margin-top: 30px;
    }
    .totalFull {
      color: #333;
      font-size: 18px;
    }
  }
  .detail {
    padding: 0 23px 12px;
    font-weight: bold;
    .instructios {
      width: 70%;
    }
    .instructioEn {
      width: 65%;
    }
    .mt {
      margin-top: 24px;
    }
  }
  .command {
    width: 301px;
    height: 32px;
    line-height: 32px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.18);
    border-radius: 2px;
    color: #606266;
    margin: 0 auto 10px;
  }
  .lookBtn {
    width: 119px;
    height: 30px;
    line-height: 30px;
    margin: 20px auto;
    background: linear-gradient(180deg, #ffe071 0%, #ffb83c 100%);
    box-shadow: 0px 2px 11px 0px rgba(160, 110, 17, 0.61),
      0px 1px 3px 0px rgba(255, 255, 255, 0.78);
    border-radius: 15px;
  }
  .copyBtn {
    width: 119px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    background: linear-gradient(180deg, #6fbcff 0%, #3283f6 100%);
    box-shadow: 0px 2px 11px 0px rgba(160, 110, 17, 0.61),
      0px 1px 3px 0px rgba(255, 255, 255, 0.78);
    border-radius: 15px;
  }
  .isUsed {
    position: absolute;
    top: 0;
    width: 347px;
    height: 532px;
    background: #d8d8d8;
    border-radius: 12px;
    opacity: 0.85;
  }
  .isUsedImg {
    position: absolute;
    bottom: 20px;
    right: 5px;
  }
}
</style>